import { FloatingPortal } from "@floating-ui/react";
import { FloatingHTMLOverlay } from "./FloatingHTMLOverlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faXmark } from "@fortawesome/free-solid-svg-icons";
import { type CachedList, editList, leocache } from "~/utils/leocache";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { ListPreview, ListsLoader } from "~/routes/lists/index";
import { toast } from "react-toastify";
import { useAppStore } from "~/store";

export default function ListsModal({}) {
  const [activeAccount, keys, isDarkMode, { modal, author }, setListUtilities] =
    useAppStore(store => [
      store.account.activeAccount,
      store.account.keys,
      store.settings.dark,
      store.utilities.lists,
      store.utilities.setLists
    ]);

  const [lists, setLists] = useState<CachedList[] | null>(null);
  const [selectedList, setSelectedList] = useState<CachedList | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const { signature, publicKey, proxy } = keys;

  useEffect(() => {
    if (lists !== null) return;

    if (!activeAccount || !publicKey || !signature) {
      return setLists([]);
    }

    void (async function () {
      const lists = await leocache.getAuthorLists(
        activeAccount?.name,
        publicKey,
        signature,
        proxy === "hivesigner" // hivesigner
      );
      setLists(lists);
    })();
  }, []);

  const closeModal = () => {
    setListUtilities({
      modal: false,
      author: undefined
      // permlink: undefined
    });
  };

  const handleAdd = async () => {
    if (loading) return;

    if (!activeAccount || !signature || !publicKey) {
      return toast("Please sign in with Hive Keychain or HiveSigner.", {
        type: "error",
        theme: isDarkMode ? "dark" : "light",
        autoClose: 3_000
      });
    }

    if (!selectedList) {
      return toast("You need to select a list to add.", {
        type: "error",
        theme: isDarkMode ? "dark" : "light",
        autoClose: 3_000
      });
    }

    setLoading(true);

    const add = await editList(
      activeAccount?.name,
      signature,
      publicKey,
      proxy === "hivesigner",
      {
        field: "users",
        type: "add",
        author: activeAccount?.name,
        list: selectedList.slug,
        update: author as string
      }
    );

    if (!add.ok) {
      setLoading(false);
      return toast("There is an error occured while adding user to list.", {
        type: "error",
        theme: isDarkMode ? "dark" : "light",
        autoClose: 3_000
      });
    }

    setLoading(false);
    toast("User successfully added to list.", {
      type: "success",
      theme: isDarkMode ? "dark" : "light",
      autoClose: 3_000
    });

    closeModal();
  };

  return !modal ? null : (
    <FloatingPortal>
      <FloatingHTMLOverlay
        onClick={ev => ev.stopPropagation()}
        className="duration-50 backdrop-blur-sm bg-overlay z-[1000] flex justify-center py-[10vh]"
        lockScroll
      >
        <div className="animate-reveal-form relative flex-0 bg-pri dark:bg-pri-d w-full m-4 pc:m-0 sm:w-[440px] rounded-xl border border-pri dark:border-pri-d h-min">
          <div
            onClick={() => closeModal()}
            className="absolute p-2 right-4 top-4 h-10 w-10 flex items-center justify-center rounded-full hover:bg-neutral-200 dark:hover:bg-neutral-700 transition-colors cursor-pointer"
          >
            <FontAwesomeIcon icon={faXmark} />
          </div>
          <span className="top-[22px] px-6 relative items-center justify-center font-bold text-xl mt-6">
            Select List
          </span>
          <div className="flex flex-1 flex-col px-6 py-6 pt-10 divide-y divide-solid divide-pri dark:divide-pri-d">
            {lists === null ? (
              <ListsLoader />
            ) : lists?.length > 0 ? (
              lists
                .filter(list => list.account === activeAccount?.name)
                .map(list => (
                  <ListPreview
                    key={list.slug}
                    list={list}
                    selected={selectedList}
                    author={author!}
                    onClick={list => setSelectedList(list)}
                  />
                ))
            ) : (
              "There is no list that you owned."
            )}
          </div>

          <div className="flex items-center justify-end gap-x-2 mt-2 pb-5 pr-6">
            <button
              className="py-2 px-4 rounded-full bg-neutral-200 dark:bg-neutral-700 text-sm font-medium hover:bg-neutral-300 dark:hover:bg-neutral-600 transition-all duration-150"
              onClick={() => closeModal()}
            >
              Cancel
            </button>

            <button
              onClick={() => handleAdd()}
              className={classNames(
                "py-2 px-4 rounded-full bg-acc text-sm font-medium hover:bg-acc-hov transition-all duration-150",
                {
                  "opacity-60 cursor-not-allowed":
                    loading || selectedList === null
                }
              )}
              disabled={loading || selectedList === null}
            >
              {loading ? (
                <span className="flex items-center gap-x-2">
                  <span>Saving</span>
                  <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
                </span>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      </FloatingHTMLOverlay>
    </FloatingPortal>
  );
}
