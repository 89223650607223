import { Link, useLocation } from "@remix-run/react";

export default function NewToLeoFinance() {
  const {pathname} = useLocation();
  return (
    <div className="relative flex flex-col p-4 mt-3 ml-2 gap-6 border border-pri dark:border-pri-d rounded-lg">
      <div className="flex flex-col gap-2">
        <h1 className="text-lg">New to InLeo?</h1>
        <p className="text-sm text-gray-600 dark:text-gray-500">
          InLeo is the #1 web3 social media platform.
          <br />
          <br />
          Join 5,000+ active users!
        </p>
      </div>

      <Link
        prefetch="intent"
        to={`/signup?redirectTo=${pathname}`}
        className="flex flex-1"
      >
        <button className="flex flex-1 justify-center items-center px-4 py-2 text-sm font-semibold bg-acc text-pri rounded-2xl transition-colors hover:bg-acc/[.85]">
          Sign up to InLeo
        </button>
      </Link>
    </div>
  );
}
